<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title">
                    {{ gameInfo.title }}
                </div>
                <!-- <div class="top-rat">
                    <van-rate
                    v-model="gameInfo.rating"
                    readonly
                    :size="15"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <div class="rat">
                        {{gameInfo.rating}}
                    </div>
                </div> -->
                <div class="top-btn" @click="()=>{isPlay = false}">
                    PLAY
                </div>
            </div>
            <div class="gamewin" v-else>
                <iframe
                    style="width:100%"
                    :src="baseUrl+gameInfo.url"
                    frameborder="0"></iframe>
            </div>
            <bannerRd></bannerRd>
            <div class="details-description">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{ gameInfo.synopsis }}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h">
                    <p>More Games</p>
                    <!-- <div>more</div> -->
                </div>
                <div class="cont-st-card" v-for="item in gameList" :key="item.id" @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{ item.title }}</p>
                    <!-- <van-rate
                    v-model="value"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    /> -->
                </div>
                <span v-for="item in 8" :key="item+'s'"></span>
            </div>
        </div>
        <anchorNd v-if="isPlay"></anchorNd>
    </div>
</template>

<script>
import {isMobile, getRandomGames, scrollToTop} from "@/utils/api"
import {getGameById} from '@/api/games'
import bannerRd from "@/components/adContent/banner3.vue";
import anchorNd from "@/components/adContent/anchor2.vue";

export default {
    components: {
        bannerRd,
        anchorNd
    },
    data() {
        return {
            value: 4,
            gameList: [],
            gameInfo: {},
            spanNum: 0,
            isPlay: true,
            baseUrl: ''
        }
    },
    mounted() {
        let id = this.$route.query.id

        this.getGameInfo(id);
        // this.getRandomGame();

        if (isMobile()) {
            this.spanNum = 3 - 2
        } else {
            this.spanNum = 11 - 2
        }

        // this.baseUrl = process.env.VUE_APP_BASE_GAME
        this.baseUrl = 'https://' + window.location.hostname

        scrollToTop(0);
    },
    methods: {
        async getGameInfo(id) {
            let result = await getGameById({id});
            this.gameInfo = result.game;
            this.gameList = result.similarGames;
        },
        getRandomGame() {
            this.gameList = getRandomGames(0, 300, 15)
            console.log(this.gameList);
        },
        goDetails(item) {
            window.location.href = `${window.location.origin}/details?id=${item.id}`
        }
    },
}
</script>

<style lang="less" scoped>
.detail {
    padding: 0 20px;
    box-sizing: border-box;

    .details-top {
        margin-bottom: 22px;

        div {
            width: 200px;
            margin: 0 auto;
            text-align: center;
        }

        .top-img {
            width: 200px;
            height: 200px;
            border-radius: 24px;
            margin: 24px auto 4px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .top-title {
            // width: 106px;
            height: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            margin: 4px auto 6px;
        }

        // .top-rat{
        //     width: 115px;
        //     margin-bottom: 16px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     .rat{
        //         width: 26px;
        //         height: 20px;
        //         font-family: PingFangSC, PingFang SC;
        //         font-weight: 500;
        //         font-size: 14px;
        //         color: #FF0000;
        //         line-height: 20px;
        //         text-align: left;
        //         font-style: normal;
        //     }
        // }
        .top-btn {
            width: 330px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 20px;
            cursor: pointer;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #994B46;
            line-height: 40px;
            font-style: normal;
            text-transform: uppercase;
        }
    }

    .gamewin {
        width: 100%;
        height: calc(100ch - 50px);

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .details-description {
        margin-bottom: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        // text-align: center;
        font-style: normal;

        .des-top {
            font-size: 16px;
        }

        .des-body {
            font-size: 14px;
        }
    }

    .cont-st {
        // padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cont-st-h {
            width: 100%;
            // background: #504BA9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                width: 100%;
                height: 25px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #521E1B;
                line-height: 25px;
                text-align: center;
                font-style: normal;
            }

            div {
                margin-right: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
            }
        }

        .cont-st-card {
            margin: 12px 0;
            padding-top: 6px;
            box-sizing: border-box;
            width: 101px;
            // height: 152px;
            // background: #FFFFFF;
            // border-radius: 11px;
            text-align: center;

            img {
                width: 101px;
                height: 101px;
                border-radius: 15px;
            }

            p {
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }

        span {
            width: 101px;
        }
    }

    .no-warp {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .warp-6 {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>